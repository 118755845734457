.navigationItemClicked,
.navigationItem {
	text-decoration: none;

	cursor: pointer;
}

.navigationItemClicked {
	color: orangered;
}

.navigationItem {
	color: white;
}
