@import url('https://fonts.googleapis.com/css?family=Kodchasan:300,400,500,700&display=swap');
$Kodchasan: 'Kodchasan', sans-serif;

body {
	margin: 0;

	font-family: $Kodchasan;
	font-size: 1.2rem;
	font-weight: 300;
}
