.navigation {
	display: flex;

	position: fixed;
	top: 0;

	box-sizing: border-box;
	padding: 1.6rem;
	width: 100%;

	align-items: center;
	gap: 1.6rem;
	justify-content: space-between;

	background-image: linear-gradient(to top, rgba(black, 0) 0%, rgba(black, 0.9) 100%);
}
