@import url('https://fonts.googleapis.com/css?family=Caveat&display=swap');
$Caveat: 'Caveat', cursive;

.mainHeader {
	padding: 8rem 1.6rem 2.4rem;

	background: linear-gradient(rgba(black, 0.3), rgba(black, 0.3)), url('../assets/images/background.webp');
	background-clip: border-box;
	background-origin: border-box;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	h1,
	h2,
	h3 {
		margin: 0;

		text-align: center;
		text-shadow: 0 0 1.6rem black;
	}
	h1,
	h2 {
		color: white;
		text-transform: uppercase;
	}

	h1,
	h3 {
		font-size: 4rem;
	}

	h2 {
		font-size: 1.6rem;
	}

	h3 {
		color: orangered;
		font-family: $Caveat;
	}
}

@media (max-width: 880px) {
	.mainHeader {
		h1,
		h3 {
			font-size: 2.4rem;
		}
	}
}
