.infoHovered,
.info {
	margin: 0.8rem;
	width: 24rem;

	cursor: default;

	a {
		color: orangered;
		font-weight: 700;
		text-decoration: none;
	}

	span {
		font-size: 1rem;
	}
}

.infoHovered {
	color: orangered;
}

.info {
	color: black;
}

@media (max-width: 880px) {
	.infoHoverer,
	.info {
		width: 100%;
	}
}
