.news {
	display: flex;

	padding: 0.8rem 1.6rem;

	flex-direction: column;
	gap: 0.4rem;

	color: white;
	text-align: center;

	background-color: orangered;

	box-shadow: 0 0 1.6rem rgba(black, 0.2);

	h4,
	p {
		margin: 0;
	}
}
