.logo {
	color: white;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;

	cursor: pointer;
}

@media (max-width: 880px) {
	.logo {
		display: none;
	}
}
