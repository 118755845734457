.infos {
	display: flex;

	height: 100%;
	padding: 1.6rem;

	flex-wrap: wrap;
	justify-content: space-between;
}

.smallerFont {
	font-size: 1rem;
}

.bold {
	font-weight: 700;
}
